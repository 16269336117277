<template>
  <v-row class="statements">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">Statements</div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-sm-15">
        <div class="accent py-2 px-sm-10 px-2 custom">
          <v-card width="100%" color="accent" flat class="d-md-flex align-md-end">
            <v-autocomplete
              v-model="account"
              :items="accountsList"
              prepend-inner-icon="mdi-magnify"
              dense
              outlined
              color="primary"
              item-text="name"
              item-value="id"
              hide-details
              height="40"
              class="mr-5 my-sm-0 my-1"
              @change="getData"
            ></v-autocomplete>
            <v-select
              v-model="date"
              :items="periods"
              item-text="title"
              item-value="name"
              dense
              outlined
              hide-details
              color="primary"
              height="40"
              @change="getData"
              class="mr-5 my-sm-0 my-1"
            ></v-select>
            <span v-if="date == 'custom'">
              <v-menu v-model="modalFrom" :close-on-content-click="false" fixed transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-5 my-sm-0 my-1"
                    v-model="dateFrom"
                    placeholder="From"
                    hide-details
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    required
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    height="40"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :max="dateTo"
                  color="primary"
                  v-model="dateFrom"
                  @input="modalFrom = false"
                  @change="getData"
                ></v-date-picker>
              </v-menu>
              <v-menu v-model="modalTo" :close-on-content-click="false" fixed transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-5 my-sm-0 my-1"
                    v-model="dateTo"
                    placeholder="To"
                    hide-details
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    required
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    height="40"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :min="dateFrom"
                  :max="new Date().toISOString().substr(0, 10)"
                  color="primary"
                  v-model="dateTo"
                  @input="modalTo = false"
                  @change="getData"
                ></v-date-picker>
              </v-menu>
            </span>
            <v-btn v-if="date == 'custom' || !data.result.length" text color="gray" @click="clear">
              Clear <v-icon>mdi-close mdi-18px</v-icon>
            </v-btn>
          </v-card>
        </div>
        <div>
          <div v-if="nothing" class="pb-3">
            <div class="d-flex ms-md-8 ms-3">
              <div style="max-width: 190px" class="opasity--text f18 font-weight-medium text-center mt-10">
                Select an account to show statements
              </div>
              <img src="@/assets/icon/arrow-up.svg" alt="arrow" />
            </div>
            <v-card flat height="55vh" min-height="240" class="d-flex align-center justify-center flex-column">
              <v-img class="mt-7" max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
              <div class="text-center text-h5 font-weight-bold mt-8">Sorry, but nothing found</div>
            </v-card>
          </div>
          <div v-else-if="block || !data.result.length" class="pb-3">
            <div class="d-flex ms-md-8 ms-3">
              <div style="max-width: 190px" class="opasity--text f18 font-weight-medium text-center mt-10">
                Select an account to show statements
              </div>
              <img src="@/assets/icon/arrow-up.svg" alt="arrow" />
            </div>
            <v-card flat height="55vh" min-height="240" class="d-flex align-center justify-center flex-column">
              <v-img class="mt-7" max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
              <div class="text-center text-h6 font-weight-bold mt-8">There is no record to show</div>
            </v-card>
          </div>
          <div v-else>
            <v-data-table
              hide-default-footer
              fixed-header
              :headers="headers"
              :items="data.result"
              :items-per-page="Number(itemsPerPage)"
              :height="'calc(100vh - 287px)'"
              class="d-sm-block d-none p-table"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id" height="52">
                    <td>{{ item.billing_period }}</td>
                    <td>{{ item.payment_date }}</td>
                    <td>{{ item.start_balance }}</td>
                    <td>{{ item.debit }}</td>
                    <td>{{ item.credit }}</td>
                    <td class="text-end">
                      {{ item.end_balance }}
                      <img :src="require(`@/assets/icon/${item.type != 'debit' ? 'up' : 'down'}.svg`)" alt="arrow up" />
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-slot:footer>
                <v-divider></v-divider>
                <v-row class="mt-2 pb-2 px-10" align="center" justify="end">
                  <span class="grey--text">Rows per page:</span>
                  <div class="perPageSelect">
                    <v-select
                      v-model="itemsPerPage"
                      :items="itemsPerPageArray"
                      menu-props="auto"
                      hide-details
                      single-line
                      dense
                      @change="getData"
                    ></v-select>
                  </div>
                  <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="mx-4 grey--text">{{ page }} of {{ data.pages }}</span>
                  <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </template>
            </v-data-table>
            <div class="d-block d-sm-none">
              <div v-for="item in data.result" :key="item.id" class="px-4 pt-2">
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Billing period</div>
                  <div class="font-weight-medium f18">{{ item.billing_period }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Payment date</div>
                  <div class="font-weight-medium f18">{{ item.payment_date }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Start balance</div>
                  <div class="font-weight-medium f18">{{ item.start_balance }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Debite</div>
                  <div class="font-weight-medium f18">{{ data.debit }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Credit</div>
                  <div class="font-weight-medium f18">{{ item.credit }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">End balance</div>
                  <div class="font-weight-medium f18">
                    {{ item.end_balance }}
                    <img :src="require(`@/assets/icon/${item.type != 'debit' ? 'up' : 'down'}.svg`)" alt="arrow up" />
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
              <v-row class="my-2 pb-2" align="center" justify="center">
                <span class="grey--text">Rows per page:</span>
                <div class="perPageSelect">
                  <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageArray"
                    menu-props="auto"
                    hide-details
                    single-line
                    dense
                    @change="getData"
                  ></v-select>
                </div>
                <div>
                  <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="mx-3 mt-1 grey--text">{{ page }} of {{ data.pages }}</span>
                  <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
      itemsPerPage: +this.$route.query.page_size || 10,
      page: +this.$route.query.page || 1,
      account: this.$route.query.account || 'all',
      date: this.$route.query.date || 'this_year',
      dateFrom: this.$route.query.date_from || new Date().toISOString().substr(0, 10),
      dateTo: this.$route.query.date_to || new Date().toISOString().substr(0, 10),
      modalFrom: false,
      modalTo: false,
      block: true,
      nothing: false,
      headers: [
        { text: 'Billing period', value: 'billing_period', sortable: false },
        { text: 'Payment date', value: 'payment_date', sortable: false },
        { text: 'Start balance', value: 'start_balance', sortable: false },
        { text: 'Debite', value: 'debit', sortable: false },
        { text: 'Credit', value: 'credit', sortable: false },
        { text: 'End balance', align: 'end', value: 'end_balance', sortable: false },
      ],
      periods: [
        { name: 'this_year', title: 'This year' },
        { name: 'past_year', title: 'Past year' },
        { name: 'custom', title: 'Custom' },
      ],
      statusError: null,
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getPagination');
    this.$store.dispatch('getAccountsAll').then(() => {
      this.accountsList.unshift({ id: 'all', name: 'All' });
    });
  },
  methods: {
    notifi(btn) {
      if (btn == '400') {
        this.$notify({ message: 'Error', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    clear() {
      this.account = 'all';
      this.date = 'this_year';
      this.dateFrom = new Date().toISOString().substr(0, 10);
      this.dateTo = new Date().toISOString().substr(0, 10);
      this.getData();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store
        .dispatch('getStatementsList', {
          pageSize: this.itemsPerPage,
          account: this.account,
          page: this.page,
          date: this.date,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        })
        .then(() => {
          this.nothing = false;
          this.block = false;
        })
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'account__not_found') {
        this.$store.dispatch('setStatementsList', { result: [] });
        this.block = true;
      } else if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setStatementsList', { result: [] });
        this.nothing = true;
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('400');
      } else {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.statementsList;
    },
    itemsPerPageArray() {
      return this.$store.getters.pagination;
    },
    accountsList() {
      return this.$store.getters.accountsAll;
    },
  },
  destroyed() {
    this.$store.dispatch('setStatementsList', { result: [] });
    this.$store.dispatch('setAccountsAll', []);
  },
};
</script>

<style lang="scss">
.statements {
  .custom {
    .v-input {
      display: inline-block;
      max-width: 160px;
      width: 100%;
      .v-input__append-inner {
        margin-top: 8px !important;
      }
    }
    .v-autocomplete {
      max-width: 240px;
    }
  }
}
</style>
